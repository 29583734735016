// RapidAPI configuration
const rapidAPIKey = "3f67dd59b8msh0bf1aa122e11ed2p1f34fdjsn22bc97f6840e";
const rapidAPIHost = "zillow-com1.p.rapidapi.com";

const headers = {
  "X-RapidAPI-Key": rapidAPIKey,
  "X-RapidAPI-Host": rapidAPIHost,
};

// Fetch ZPID based on search string
export const fetchZpid = async (searchString) => {
  const url = `https://zillow-com1.p.rapidapi.com/propertyExtendedSearch?location=${encodeURIComponent(searchString)}`;
  try {
    const response = await fetch(url, { method: 'GET', headers });
    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Fetch property details based on ZPID
export const fetchProperty = async (zpid) => {
  const url = `https://zillow-com1.p.rapidapi.com/property?zpid=${zpid}`;
  try {
    const response = await fetch(url, { method: 'GET', headers });
    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Fetch property images based on ZPID
export const fetchImages = async (zpid) => {
  const url = `https://zillow-com1.p.rapidapi.com/images?zpid=${zpid}`;
  try {
    const response = await fetch(url, { method: 'GET', headers });
    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Search for properties by parameters
export const searchProperties = async (location, homeType) => {
  const url = `https://zillow-com1.p.rapidapi.com/propertyExtendedSearch?location=${encodeURIComponent(location)}&home_type=${homeType}`;
  try {
    const response = await fetch(url, { method: 'GET', headers });
    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
};
