
import React, { useEffect, useState } from "react";
import { zipsAPI } from "./utils/env";
import { fetchZpid, fetchProperty, fetchImages } from "./utils/rapidApi";
import "./components/App.css";
import Header from "./components/Header.jsx";
import Images from "./components/Images.jsx";
import SearchBar from "./components/SearchBar.jsx";
import Modal from "./components/Modal.jsx";
import Footer from "./components/Footer.jsx";
import Nav from "./components/Nav.jsx";
import About from "./components/About.jsx";
import Multi from "./components/Multi.jsx";



// from previous project

const App = () => {
  //useState for display of information from api call
  const [searched, showModal] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [images, setImages] = useState([]);
  //default/placeholder
  const [results, setResults] = useState({
    county: "",
    heat: "",
    wet_bulb: "",
    farm_crop_yields: "",
    sea_level_rise: "",
    large_fires: "",
    economic_damage: "",
  });
  //update in listing
  const [property, setProperty] = useState({
    state: "",
    city: "",
    streetAddress: "",
    zipCode: "",
    zpid: "",
    zestimate: "",
    livingAreaSqft: "",
    price: "",
    bathrooms: "",
    bedrooms: "",
    year: "",
    type: "",
    taxRate: "",
    hoinsurance: "",
    desc: "",
  });

  //tax history
  const [taxHist, setTaxHist] = useState([]);
  //price history
  const [priceHist, setPriceHist] = useState([]);
  //near by Homes
  const [nearByHomes, setNearByHomes] = useState([]);
  //near by schools
  const [nearBySchools, setNearBySchools] = useState([]);
  //resofacts (facts and features of interior)
  const [resoFacts, setResoFacts] = useState({});

  //zillow id
  const [zpid, setZpid] = useState("");

  //will re-render the component when searchbar is cleared
  const [rerender, setRerender] = useState(false);

  //flag if multiple listings under the same address
  const [multi, setMulti] = useState(false)
  const [properties, setProperties] = useState([]);

  

  //check for error input 
  const [addressError, setError] = useState(false)

  const searchZpid = async (addressInput) => {
    const zpidData = await fetchZpid(addressInput);
     console.log("search for zpid", zpidData);
        // for multiple listings under one address use: 184 7th St,San Francisco CA 94103 
        if (Array.isArray(zpidData)) {
          console.log(zpidData);
          showModal(false)
          setMulti(true)
          setProperties(zpidData)
        } else if (Object.keys(zpidData).length === 0) {
          showModal(false)
          console.log(zpidData);
          //show error on input
          setError(true);
        } else {
          var propertyZpid = zpidData.zpid;
          setZpid(propertyZpid);
          getPropertyData(propertyZpid);
        }
        //need to set up flag for multiple address, call getPropertyData on each to get addresses
       
  };

  const getPropertyImages = async (zpid) => {
    const imagesData = await fetchImages(zpid);
        console.log("images incoming", imagesData);
        setImages(imagesData.images);
        setLoading(false);
  };

  //pass down to the stats to be able to reload with new address
  //add useEffect to reload upon change of zpid or address
  const getPropertyData = async (propertyZpid) => {
    const propertyData = await fetchProperty(propertyZpid);
        console.log("incoming property data", propertyData);
        var resultProperty = propertyData;
        //   "Not Listed"
        setProperty({
          state: `${
            resultProperty.address.state
              ? resultProperty.address.state
              : "Not Provided"
          }`,
          city: `${
            resultProperty.address.city
              ? resultProperty.address.city
              : "Not Provided"
          }`,
          streetAddress: `${
            resultProperty.address.streetAddress
              ? resultProperty.address.streetAddress
              : "Not Provided"
          }`,
          zipCode: `${
            resultProperty.address.zipcode
              ? resultProperty.address.zipcode
              : "Not Provided"
          }`,
          zpid: `${resultProperty.zpid ? resultProperty.zpid : "Not Provided"}`,
          zestimate: `${
            resultProperty.zestimate ? resultProperty.zestimate : "Not Provided"
          }`,
          livingAreaSqft: `${
            resultProperty.livingArea
              ? resultProperty.livingArea
              : "Not Provided"
          }`,
          price: `${
            resultProperty.price ? resultProperty.price : "Not Provided"
          }`,
          bathrooms: `${
            resultProperty.bathrooms ? resultProperty.bathrooms : "Not Provided"
          }`,
          bedrooms: `${
            resultProperty.bedrooms ? resultProperty.bedrooms : "Not Provided"
          }`,
          year: `${
            resultProperty.yearBuilt ? resultProperty.yearBuilt : "Not Provided"
          }`,
          type: `${
            resultProperty.homeType ? resultProperty.homeType : "Not Provided"
          }`,
          taxRate: `${
            resultProperty.propertyTaxRate
              ? resultProperty.propertyTaxRate
              : "Not Provided"
          }`,
          hoinsurance: `${
            resultProperty.annualHomeownersInsurance
              ? resultProperty.annualHomeownersInsurance
              : "Not Provided"
          }`,
          desc: `${
            resultProperty.description
              ? resultProperty.description
              : "Not Provided"
          }`,
        });

        setTaxHist(resultProperty.taxHistory);

        setPriceHist(resultProperty.priceHistory);

        setNearByHomes(resultProperty.nearbyHomes);

        setNearBySchools(resultProperty.schools);

        setResoFacts(resultProperty.resoFacts);
        setTimeout(() => {
          getPropertyImages(propertyZpid);
          showModal(true);
        }, 1500);
    
  };

  //  look up county by zipcode
  const searchByZip = async (zip) => {
      //returns:
      /*
    {county: "San Francisco County", economicDamages: 4, farmCropYield: 1, heat: 3, seaLevelRise: 4, 
    state: "CA", totalDamages: 4, veryLargeFires: 1, wetBulb: 2, zip: "94102"}
      */
      const res = await fetch(`${zipsAPI()}?zip=94102`);
      // console.log(await res.json());
      const data = await res.json();
        console.log('fetching from firebase', data);
        var results = data;
        setResults({
          county: `${results.county}`,
          heat: `${results.heat}`,
          wet_bulb: `${results.wetBulb}`,
          farm_crop_yields: `${results.farmCropYield}`,
          sea_level_rise: `${results.seaLevelRise}`,
          large_fires: `${results.veryLargeFires}`,
          economic_damage: `${results.economicDamages}`,
        });
        showModal(true);
  };

  
  //test the data fetch
  // return (
  //   <div className="App">
  //       <button
  //         onClick={() =>
  //           searchZpid("200 Dolores St #8, San Francisco, CA 94103")
  //         }
  //       >
  //         Test Property 1
  //       </button>
  //     {/* </header> */}
  //   </div>
  // );

  return (
    <div className="main">
      <Header />
      <Nav />
      <SearchBar
        searchByZip={searchByZip}
        showModal={showModal}
        searchZpid={searchZpid}
        isLoading={isLoading}
        setLoading={setLoading}
        rerender={rerender}
        setRerender={setRerender}
        searched={searched}
        results={results}
        property={property}
        images={images}
        taxHist={taxHist}
        priceHist={priceHist}
        nearByHomes={nearByHomes}
        nearBySchools={nearBySchools}
        resoFacts={resoFacts}
        zpid = {zpid}
        setZpid = {setZpid}
        multi = {multi}
        setMulti = {setMulti}
        properties = {properties}
        setError = {setError}
        addressError = {addressError}
        getPropertyData = {getPropertyData}
      />
      {addressError ? <div>
        <h3>Hmm..can't find the address you are looking for, check and try again!</h3>
      </div> : null}
      {/* uncomment line below and loader in modal */}
     
      <About />
      <Footer />
    </div>
  );



}

export default App;
